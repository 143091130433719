/* eslint-disable react/no-danger */
import { graphql, Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import React from 'react';

import config from '../../site-config';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

import '../theme/post.css';

const PostTemplate = ({
  data,
}) => {
  if (typeof window === 'undefined') {
    return null;
  }

  const { t, i18n } = useTranslation();
  const { allMarkdownRemark } = data;
  const { edges } = allMarkdownRemark;
  const post = edges.find((edge) => (
    edge.node.frontmatter.language === i18n.languages[0]
  ));

  const { node } = post || {};
  const { frontmatter, html } = node || {};
  const date = new Date((frontmatter || {}).date || '');

  return (
    <Layout>
      <SEO title={(frontmatter || {}).title} />
      <section className="container py-8 w-3/5 mx-auto">
        {post && (
          <>
            <>
              <h2 className="my-2">
                {frontmatter.title}
              </h2>
              <p className="my-2 text-gray-500 font-light">
                {t(
                  'author',
                  {
                    author: frontmatter.author,
                    date: date.toLocaleString(i18n.languages[0], config.dateFormat),
                  }
                )}
              </p>
            </>
            <div
              className="post-content"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </>
        )}
        {!post && (
          <div className="py-16">
            <h3 className="text-center text-gray-500">
              {t('postNotAvailable')}
            </h3>
            {edges.length > 0 && (
              <p className="subtitle text-center text-gray-500 mt-4">
                {t('postNotAvailableChangeLanguageExplain')}
              </p>
            )}
            {edges.length <= 0 && (
              <p className="subtitle text-center text-gray-500 mt-4">
                {t('postNotAvailableChangeLanguageExplain')}
              </p>
            )}
          </div>
        )}
        <div className="pt-16 text-center">
          <Link to="/blog">
            {t('backToBlog')}
          </Link>
        </div>
      </section>
    </Layout>
  );
};

PostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
};

export const pageQuery = graphql`
  query($slug: String!) {
    allMarkdownRemark(
      filter: { frontmatter: { slug: { eq: $slug } visible: { eq: true } } }
    ) {
      edges {
        node {
          html
          frontmatter {
            author
            date
            language
            slug
            title
          }
        }
      }
    }
  }
`;

export default PostTemplate;
